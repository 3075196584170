// Import the necessary Firebase modules
import { initializeApp } from "firebase/app";

import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';

// Your Firebase config here
const firebaseConfig = {
    apiKey: "AIzaSyCkKBgQVh3AToFPN2VxqfLL47O0MD6u9yE",
    authDomain: "khelbabu-6360f.firebaseapp.com",
    projectId: "khelbabu-6360f",
    storageBucket: "khelbabu-6360f.appspot.com",
    messagingSenderId: "772186684088",
    appId: "1:772186684088:web:7eddaf78dc005a99b8124d",
    measurementId: "G-PDBZRMXFKX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAuth.languagecode='it';
const auth = getAuth(app);

// Export Firebase auth and phone auth functions
export { auth, signInWithPhoneNumber, RecaptchaVerifier };
