import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CiMenuFries } from "react-icons/ci";
import Sidebar from './Sidebar';

const Navbar = () => {
  const [shadow, setShadow] = useState(false);
  const [open, setopen] = useState(false);
  const user = localStorage.getItem('user');

  const handleopen = () => {
    setopen(!open);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`flex items-center justify-between p-4 shadow-md shadow-black bg-red-800 sticky top-0 w-full z-50`}>
        <div className='w-1/2 flex items-center'>
          {user ? (
            <CiMenuFries color='white' size='2em' onClick={handleopen} />
          ) : (
            <Link to='/'>
              <img className='w-8 h-8 object-contain' src='https://cdn.pixabay.com/photo/2021/10/11/17/26/e-sports-6701392_640.png' alt='Logo' />
            </Link>
          )}
        </div>
        <div className='w-1/2 flex justify-end space-x-4'>
          {user ? (
            <p>wallet</p>
          ) : (
            <>
              <Link to='/login'>
                <button className='px-4 py-2 border-2 rounded-md text-white hover:bg-black hover:underline'>Signup</button>
              </Link>
              <Link to='/login'>
                <button className='px-4 py-2 border-green border-2 rounded-md text-white hover:bg-black hover:underline'>Login</button>
              </Link>
            </>
          )}
        </div>
      </div>
      <Sidebar open={open} setopen={setopen} />
    </>
  );
}

export default Navbar;
