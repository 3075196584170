import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';
import HomePage from './pages/Home';
import Login from './pages/Login';
import Profile from './pages/Profile';



const App = () => {
  return (
    <Router>
      <Routes>
      
        <Route path="/" element={ <HomePage />} />
        <Route path='/login' element={<Login/>}/>
        <Route path='/profile'  element={<ProtectedRoute><Profile/></ProtectedRoute>  }/>
    
      </Routes>
    </Router>
  );
};

export default App;
