import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link,useNavigate } from "react-router-dom";
import { auth, RecaptchaVerifier, signInWithPhoneNumber } from "../firebase";
import axios from 'axios';

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const apiUrl="www.backend.khelbabu.com/";
  const navigate=useNavigate();

  useEffect(() => {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier(auth,'recaptcha-container', {
      size: 'invisible', // Can be 'invisible' or 'normal'
      callback: (response) => {
        console.log('reCAPTCHA solved:', response);
      },
      'expired-callback': () => {
        console.log('reCAPTCHA expired');
      }
    });
  }
}, []);

  const onSignInSubmit = (e) => {
    e.preventDefault();
    console.log(phoneNumber)
    const appVerifier = window.recaptchaVerifier;
    console.log(appVerifier)
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
        console.log(confirmationResult)
      })
      .catch((error) => {
        console.error('Error during sign-in:', error);
      });
  };

  const onSubmitOtp = (e) => {
    e.preventDefault();
    if (confirmationResult) {
      confirmationResult.confirm(otp)
        .then((result) => {
          const user = result.user;
          axios.post(`${apiUrl}v1/auth/login`, { uid: user.uid, phoneNumber },{ withCredentials: true })
            .then(response => {
              const user=response.data.user;
              localStorage.setItem('user',JSON.stringify( user));
               navigate('/')
            })
            .catch(error => {
              console.error('Error during API call:', error);
            });
        })
        .catch((error) => {
          console.error('Error during OTP confirmation:', error);
        });
    }
  };

  return (
    <div className="relative bg-black h-screen flex flex-col items-center justify-center overflow-hidden">
      <div id="recaptcha-container"></div> {/* Container for reCAPTCHA */}
      <div>
        <video
          className="absolute inset-0 object-cover h-2/3"
          src="https://media.istockphoto.com/id/164185925/video/pair-of-aces-falling-on-a-table.mp4?s=mp4-640x640-is&k=20&c=eKErU1ad6pET2lsKyKUL2o2Li4uSrEzwCu6EY-6xwdQ="
          autoPlay
          loop
          muted
        />
      </div>
      <div className="absolute top-4 left-16 transform -translate-x-1/2 z-20">
        <Link to='/'>
          <button className="px-6 py-2 border-white border-2 text-black rounded hover:text-black">
            <IoIosArrowBack style={{ color: "white" }} />
          </button>
        </Link>
      </div>
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black to-black z-10"></div>
      <div className="relative z-20 text-center w-full">
        <h2 className="font-bold text-2xl text-white mb-4 font-sans pb-5">
          Sign in or Sign Up
        </h2>
        <div className="bg-white rounded-xl ml-3 mr-3 h-16 flex place-content-center items-center">
          {!confirmationResult ? (
            <div className="border-gray-400 border-2 rounded-sm flex">
              <p className="bg-gray-300 p-2">+91</p>
              <input
                className="pl-5 bg-white"
                placeholder="Mobile number"
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          ) : (
            <div className="border-gray-400 border-2 rounded-sm flex">
              <p className="bg-gray-300 p-2">OTP</p>
              <input
                className="pl-5 bg-white"
                placeholder="Enter Your OTP here"
                type="tel"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
          )}
        </div>
        <div className="z-20 text-white bg-green-500 font-sans text-xl font-semibold p-3 rounded-xl m-5">
          {!confirmationResult ? (
            <button type="submit" onClick={onSignInSubmit}>Send OTP</button>
          ) : (
            <button type="submit" onClick={onSubmitOtp}>Verify OTP</button>
          )}
        </div>
      </div>
      <div className="z-20 text-white text-sm flex text-center absolute bottom-3 font-sans m-5">
        <span>By proceeding, you agree to our Terms of Use, Privacy Policy and that you are 18 years or older. You are not playing from Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, or Telangana.</span>
      </div>
    </div>
  );
};

export default Login;
