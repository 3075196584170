import React from 'react'

const Footer = () => {
  return (
    <div className='m-3'>
      <h3 className='font-medium text-gray-700 text-sm mt-3 mb-3'>Our Bussiness & Products</h3>
      <p className='text-xs  text-gray-600 mb-3'>We are an HTML5 game-publishing company and our mission is to make accessing games fast and easy by removing the friction of app-installs.</p>

      <p className='text-xs  text-gray-600 mb-3'>khelbabu is a skill-based real-money gaming platform accessible only for our users in India. It is accessible on https://www.khelbabu.com. On khelbabu, users can compete for real cash in Tournaments and Battles. They can encash their winnings via popular options such as Paytm Wallet, Amazon Pay, Bank Transfer, Mobile Recharges etc.</p>

      <h3 className='font-medium text-gray-700 text-sm mt-3 mb-3' >Our Games</h3>
      <p className='text-xs  text-gray-600 mb-3'>khelbabu has a wide-variety of high-quality, premium HTML5 games. Our games are especially compressed and optimised to work on low-end devices, uncommon browsers, and patchy internet speeds.</p>

      <p className='text-xs  text-gray-600 mb-3'>We have games across several popular categories: Arcade, Action, Adventure, Sports & Racing, Strategy, Puzzle & Logic. We also have a strong portfolio of multiplayer games such as Ludo, Chess, 8 Ball Pool, Carrom, Tic Tac Toe, Archery, Quiz, Chinese Checkers and more! Some of our popular titles are: Escape Run, Bubble Wipeout, Tower Twist, Cricket Gunda, Ludo With Friends. If you have any suggestions around new games that we should add or if you are a game developer yourself and want to work with us, don't hesitate to drop in a line at info@khelbabu.com!</p>
    </div>
  )
}

export default Footer
