import React,{useState} from 'react';
import axios from 'axios';
import { Link, useNavigate ,} from 'react-router-dom';
import Navbar from '../components/Navbar';
import Hero from  '../components/Hero';
import Footer from '../components/Footer'
import { CiYoutube } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineArrowDownward } from "react-icons/md";
import Dropdown from '../components/Dropdown';



const HomePage = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const [crossState, setcrossState]=useState(false)
  const handleClick=()=>{
      setcrossState(!crossState);
  }

  // const handleLogout = async () => {
  //   try {
  //     await axios.get('http://localhost:5000/api/v1/auth/logout', { withCredentials: true });
  //     localStorage.removeItem('user');
  //     navigate('/login');
  //   } catch (error) {
  //     console.error('Error logging out', error);
  //   }
  // };

  return (
    <>
    <Navbar />
    {!crossState ? (
      <div className='home border-black font-sans font-light border-2 p-5 text-center m-3 rounded-lg flex items-center justify-center transition-all duration-500 ease-in-out'>
        <Link to='#' className='hover:shadow-md hover:shadow-black rounded-lg'>
          <CiYoutube color='red' size='2em' />
        </Link>
        <p className='text-center w-full'>How to Play and win?</p>
        <RxCross2 size='2em' color='gray' onClick={handleClick} />
      </div>
    ) : (
      <div className='flex m-5 p-3 border-y-2 border-gray items-center transition-all duration-500 ease-in-out'>
        <CiYoutube color='red' size='1.5em' />
        <p className='ml-3 text-md font-sans'>Video Help</p>
        <div className='ml-28'>
          <MdOutlineArrowDownward size='1.2em' onClick={handleClick} />
        </div>
      </div>
    )}
    <Hero/>
    <Dropdown/>
    <Footer/>
  </>
  
  );
};

export default HomePage;
