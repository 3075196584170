import React,{useState} from 'react'
import { MdOutlineKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const Dropdown = () => {
   const [click, setclick]=useState(false);
   const handleclick=()=>{
    setclick(!click);
   }
  return (
    <div className=' bg-gray-50 border-gray-200 border-2'>
        
        <div  className='flex gap-3 p-4 items-center justify-center'>
        <img className='w-8 h-8 object-contain' src='https://cdn.pixabay.com/photo/2021/10/11/17/26/e-sports-6701392_640.png' alt='Logo' />
            <span>Terms, Privacy, Support</span>
            {!click ?<MdOutlineKeyboardArrowDown  size='2em' onClick={handleclick}/>:<MdKeyboardArrowUp  size='2em' onClick={handleclick}/>}
        </div>
        {
         click &&
         <div className='list flex gap-4 p-4 items-center justify-center font-light text-left  text-xs'>
            <div className='left'>
            <ul>
                <li className='hover:underline p-2 '>Terms & Condition</li>
                <li className='hover:underline p-2 '>Refund/Cancellation</li>
                <li className='hover:underline p-2 '>Responsible Gaming</li>
                <li className='hover:underline p-2 '>TDS Policy</li>
            </ul>
            </div>
            <div className="right">
                <ul>
                    <li className='hover:underline p-2 '>Privacy Policy</li>
                    <li className='hover:underline p-2 '>Contact Us</li>
                    <li className='hover:underline p-2 '>Platform Commission</li>
                    <li className='hover:underline p-2 '>GST Policy</li>
                </ul>
            </div>
        </div>
        }
        
        
     

    </div>
  )
}

export default Dropdown


