import React from "react";
import { CiCreditCard1 } from "react-icons/ci";
import { LuSwords } from "react-icons/lu";
import { TbLivePhoto } from "react-icons/tb";
import "./Hero.css";
const Hero = () => {
  return (
    <div className="hero  m-5 ">
      <h3 className="text-sm font-semibold ">Our Games</h3>
      <span className="flex items-center justify-center text-xs font-light gap-1 m-2"><LuSwords color="red" size='1.5em'/> is for Battles and <TbLivePhoto color="red" size='1.5em'/> is for live games <TbLivePhoto color="green" size='1.5em'/> for maintenance </span>

      <div className="grid grid-cols-2 gap-4  ">
        <div className=" border-2 rounded-lg   flex flex-col  items-center justify-center">
          <img
            src="https://cdn.pixabay.com/photo/2015/07/10/11/11/luck-839036_640.jpg"
            alt="img
            "
            className="rounded-lg"
          />
          <LuSwords color="red" style={{ position: "relative", top: "-7" }} />

          <p className="font-sans font-semibold text-sm pt-1 flex items-center justify-center w-full">
            Yodha ludo{" "}
            <span className="text-red-500 blinking-text flex  pl-3 items-center justify-center  ">
              {" "}
              <TbLivePhoto /> Live
            </span>
          </p>
        </div>
        <div className=" border-2 rounded-lg   flex flex-col  items-center justify-center">
          <img
            src="https://cdn.pixabay.com/photo/2024/03/08/11/09/tokens-8620520_640.png"
            alt="img
            "
            className="rounded-lg"
          />
          <LuSwords color="red" style={{ position: "relative", top: "-7" }} />

          <p className="font-sans font-semibold text-sm pt-1 flex items-center justify-center w-full">
            Yodha ludo{" "}
            <span className="text-red-500 blinking-text flex  pl-3 items-center justify-center  ">
              {" "}
              <TbLivePhoto /> Live
            </span>
          </p>
        </div>
        <div className=" border-2 rounded-lg   flex flex-col  items-center justify-center">
          <img
            src="https://cdn.pixabay.com/photo/2021/11/10/18/09/casino-6784518_640.jpg"
            alt="img
            "
            className="rounded-lg"
          />
          <LuSwords color="red" style={{ position: "relative", top: "-7" }} />

          <p className="font-sans font-semibold text-sm pt-1 flex items-center justify-center w-full">
            Yodha ludo{" "}
            <span className="text-red-500 blinking-text flex  pl-3 items-center justify-center  ">
              {" "}
              <TbLivePhoto /> Live
            </span>
          </p>
        </div>
        <div className=" border-2 rounded-lg   flex flex-col  items-center justify-center">
          <img
            src="https://cdn.pixabay.com/photo/2016/03/17/23/09/roulette-1264078_640.jpg"
            alt="img
            "
            className="rounded-lg"
          />
          <LuSwords color="red" style={{ position: "relative", top: "-7" }} />

          <p className="font-sans font-semibold text-sm pt-1 flex items-center justify-center w-full">
            Yodha ludo{" "}
            <span className="text-green-500 blinking-text flex  pl-3 items-center justify-center  ">
              {" "}
              <TbLivePhoto  /> main
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
